import React, { useState, useEffect } from 'react'
import { useStyles } from './TopMenu.styles'
import MenuIcon from '@mui/icons-material/Menu'
import { Typography, MenuItem, Menu, Grid } from '@mui/material'
import { Link } from 'react-router-dom'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import ActualChillds from './ActualChillds'
import {
  formatCategoriesInTopMenu,
  formatTitle,
} from '../Filter/helpers/filterList'
import getCategories from '../Filter/services/getCategories'
import { useSelector } from 'react-redux'
import { buildUrl } from 'utils/filters'

function TopMenu() {
  const { initFinished } = useSelector(({ app }) => app)
  const classes = useStyles()
  const [submenuOpen, setSubmenuOpen] = useState(false)
  const [actualChilds, setActualChilds] = useState([])
  const [actualCategory, setActualCategory] = useState(null)
  const [indexOpen, setIndexOpen] = useState(-1)
  const [categories, setCategories] = useState([])
  const [anchorEl, setAnchorEl] = useState(null)

  const open = Boolean(anchorEl)

  const handleOpenSubmenu = (childs, index, category) => {
    if (childs.length > 0) {
      setActualCategory(category)
      setActualChilds(childs)
      if (!submenuOpen) {
        setIndexOpen(index)
        setSubmenuOpen(true)
      } else {
        setIndexOpen(index)
      }
      if (index === indexOpen) {
        setSubmenuOpen(false)
        setIndexOpen(-1)
      }
    } else {
      setSubmenuOpen(false)
      setIndexOpen(-1)
    }
  }

  useEffect(async () => {
    const categoryList = await getCategories()
    const categoriesFormated = formatCategoriesInTopMenu(
      categoryList.sort((a, b) => {
        return Number(a.codigo) - Number(b.codigo)
      })
    )
    setCategories(categoriesFormated)
  }, [])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  // the client needed put the category with code 110 at the last of categories list
  const setLastItems = (itemList, identifiers) => {
    if (!itemList.length) return []
    const temp = [...itemList]
    const itemsToMove = identifiers
      .map((identifier) => {
        const index = temp.findIndex(
          (el) => el.codigo === identifier || el.code === identifier
        )
        if (index !== -1) {
          return temp.splice(index, 1)[0]
        }
        return null
      })
      .filter(Boolean)
    return [...temp, ...itemsToMove]
  }

  return (
    <div className={classes.container}>
      <div className={classes.innerContainer}>
        <div
          className={classes.openMenu}
          style={{ cursor: 'pointer' }}
          onClick={handleClick}
        >
          <MenuIcon
            sx={(theme) => ({ color: theme.palette.primary.almostBlack })}
          />
          <Typography className={classes.openMenuText}>
            Comprar por categorías
          </Typography>
        </div>
        <nav className={classes.quickAccessNav}>
          {initFinished &&
            setLastItems(
              categories.filter((c) => c.isTopMenu),
              ['PROM-', 'PROM-UO']
            ).map((item, index) => (
              <Link
                to={`/catalogo/${buildUrl(item.codigo)}`}
                key={index}
                className={
                  item.code === 'PROM-UO'
                    ? classes.quickAccessUltimasOportunidades
                    : item?.name === 'Promociones'
                    ? classes.quickAccessPromociones
                    : classes.quickAccessItem
                }
              >
                <Typography className={classes.openMenuText}>
                  {item?.name}
                </Typography>
              </Link>
            ))}
        </nav>
      </div>

      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        className={classes.menuWindow}
      >
        <div>
          {setLastItems(
            categories.filter((c) => c.isLeftMenu),
            ['110', 'PROM-UO']
          ).map((item, index) => {
            if (initFinished)
              return (
                <div key={index}>
                  <MenuItem
                    key={index}
                    className={classes.dropDownItem}
                    disabled={item?.disabled}
                    onClick={() => handleOpenSubmenu(item?.childs, index, item)}
                    disableGutters
                  >
                    <Link
                      to={`/catalogo/${buildUrl(item.codigo)}`}
                      className={classes.menuItemBox}
                      onMouseEnter={() =>
                        handleOpenSubmenu(item?.childs, index, item)
                      }
                    >
                      {Array.isArray(item.childs) && item.childs.length ? (
                        <Grid
                          container
                          alignItems='center'
                          justifyContent='space-between'
                        >
                          <Typography className={classes.dropDownItemText}>
                            {formatTitle(item?.title)}
                          </Typography>
                          <ArrowForwardIosIcon className={classes.arrowIcon} />
                        </Grid>
                      ) : (
                        <Typography className={classes.dropDownItemText}>
                          {formatTitle(item?.title)}
                        </Typography>
                      )}
                    </Link>
                  </MenuItem>
                </div>
              )
          })}
        </div>

        {submenuOpen && (
          <ActualChillds
            childs={actualChilds}
            handleClose={handleClose}
            category={actualCategory}
          />
        )}
      </Menu>
    </div>
  )
}

export default TopMenu
