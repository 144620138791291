import { makeStyles } from '@mui/styles'

const fontFamilies = {
  inter: 'Inter',
}
const fontWeights = [100, 200, 300, 400, 500, 600]

export const useStylesProducts = makeStyles((theme) => ({
  titleContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    marginBottom: '24px',
    [theme.breakpoints.up('sm')]: {
      marginTop: '28px',
    },
  },
  titleSection: {
    fontStyle: 'normal',
    fontSize: '18px',
    lineHeight: '20px',
    fontWeight: 600,
    color: theme.palette.primary.almostBlack,
    paddingLeft: '10px',
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '0px',
      fontSize: '20px',
    },
  },
  card: {
    padding: '10px',
    borderRadius: '12px',
    boxShadow: '0px 2px 14px rgba(0, 0, 0, 0.1)',
    width: '100%',
    minWidth: '120px',
    boxSizing: 'border-box',
    // height: ({ signedIn }) => (signedIn ? '410px' : '260px'),
    height: 'auto',
    minHeight: '260px',
    [theme.breakpoints.up('md')]: {
      minWidth: '245px',
      maxWidth: '100%',
      // height: ({ signedIn }) => (signedIn ? '466px' : '430px'),
      minHeight: '466px',
      padding: '20px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
    },
  },
  seeMore: {
    color: 'white',
    marginTop: '34px',
    marginBottom: '45px',
    backgroundColor: '#74A3FF',
    fontSize: '16px',
    fontFamily: 'Overpass',
    fontWeight: '400',
    borderRadius: '32px',
    padding: '11px 25px 11px 26px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '14px',
      textDecoration: 'none',
      margin: 0,
      marginLeft: '20px',
      padding: 0,
      color: theme.palette.primary.lightBlue,
      backgroundColor: 'transparent',
      borderRadius: 0,
    },
  },
  unitPrice: {
    fontStyle: 'normal',
    fontFamily: fontFamilies.inter,
    fontWeight: fontWeights[4],
    fontSize: '12px',
    lineHeight: '15px',
    color: '#9E9E9E',
    marginTop: '8px',
    width: '100%',
  },
  paymentMethod: {
    fontStyle: 'normal',
    fontFamily: fontFamilies.inter,
    fontWeight: fontWeights[4],
    fontSize: '14px',
    lineHeight: '15px',
    color: '#4AA259',
    marginBottom: '0px',
  },
  offertBox: {
    fontSize: '12px',
    fontFamily: fontFamilies.inter,
    fontWeight: fontWeights[5],
    lineHeight: '10.75px',
    marginLeft: '5px',
    letterSpacing: '0em',
    whiteSpace: 'nowrap',
    backgroundColor: '#4AA259',
    borderRadius: '2px',
    padding: '4px',
    color: 'white',
    textAlign: 'center',
    marginBottom: '0px',
  },
  offertBoxLastOpportunity: {
    fontSize: '12px',
    fontFamily: fontFamilies.inter,
    fontWeight: fontWeights[5],
    lineHeight: '10.75px',
    marginLeft: '5px',
    letterSpacing: '0em',
    whiteSpace: 'nowrap',
    backgroundColor: '#B71C1C',
    borderRadius: '2px',
    padding: '4px',
    color: 'white',
    textAlign: 'center',
    marginBottom: '0px',
  },
  productName: {
    fontFamily: fontFamilies.inter,
    fontWeight: fontWeights[3],
    fontSize: '14px',
    lineHeight: '15px',
    marginBottom: '12px',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    [theme.breakpoints.up('sm')]: {
      fontSize: '16px',
      marginBottom: '20px',
    },
  },
  productImage: {
    padding: '4px',
    marginBottom: '20px',
    width: '95%',
    objectFit: 'contain',
    [theme.breakpoints.up('sm')]: {
      minHeight: '195px',
      display: 'block',
      padding: 0,
      marginBottom: '31px',
    },
  },

  productOffert: {
    fontFamily: fontFamilies.inter,
    fontWeight: fontWeights[3],
    fontSize: '12px',
    lineHeight: '15px',
    marginBottom: '1px',
    color: '#B6B6B6',
    [theme.breakpoints.up('sm')]: {
      fontSize: '16px',
    },
  },
  productPrice: {
    fontStyle: 'normal',
    fontFamily: fontFamilies.inter,
    fontWeight: fontWeights[3],
    fontSize: '20px',
    lineHeight: '29px',
    whiteSpace: 'nowrap',
    marginBottom: '0px',
    [theme.breakpoints.up('md')]: {
      fontSize: '27px',
    },
  },
  addToCartButton: {
    backgroundColor: 'rgba(74, 162, 89, 0.1)',
    color: '#4AA259 !important',
    width: '100%',
    border: 0,
  },
  row: {
    display: 'flex',
    width: '100%',
    alignItems: 'flex-end',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  ivaText: {
    fontSize: '14px',
    fontWeight: 'bold',
    marginBottom: 5,
    marginTop: 5,
  },
  promotionFlag: {
    position: 'absolute',
    top: 16,
    left: 0,
    background: '#B71C1C',
    color: '#FFFFFF',
    padding: '8px 12px',
    borderTopRightRadius: '6px',
    borderBottomRightRadius: '6px',
    zIndex: 2,
  },
  promotionText: {
    fontWeight: 'bold',
    fontSize: '0.7rem',
    textTransform: 'uppercase',
  },
}))
