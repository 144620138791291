import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: 'white',
  },
  innerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '8px',
    [theme.breakpoints.up('sm')]: {
      boxSizing: 'border-box',
      maxWidth: '1040px',
      margin: 'auto',
      padding: '8px 0px',
    },
  },
  openMenu: {
    display: 'flex',
    alignItems: 'flex-start',
    margin: '6px 0px',
  },
  openMenuText: {
    fontWeight: 600,
    fontSize: '14px',
    color: theme.palette.primary.almostBlack,
    marginLeft: '8px',
  },

  quickAccessNav: {
    display: 'flex',
    alignItems: 'center',
  },
  quickAccessItem: {
    margin: '0px 10px',
    '& > p': {
      fontSize: '13px',
      fontWeight: 500,
    },
    color: theme.palette.primary.almostBlack,
    '&:hover': {
      color: 'white',
    },
  },
  quickAccessSubItem: {
    fontSize: '12px',
    fontWeight: 600,
    margin: '0px 10px',
    '& > p': {
      fontSize: '3px',
      fontWeight: 300,
    },
    color: theme.palette.primary.almostBlack,
    '&:hover': {
      'text-decoration': 'underline',
    },
  },
  quickAccessCTA: {
    fontSize: '13px',
    fontWeight: 600,
    textTransform: 'capitalize',
    padding: '5px 20px',
  },
  quickAccessUltimasOportunidades: {
    background: theme?.palette?.error?.main,
    color: 'white',
    padding: '7px',
    borderRadius: '4px',
    textDecoration: 'none',
    textTransform: 'uppercase',
    '&:hover': {
      background: 'darkred',
    },
    '& $openMenuText': {
      color: 'white',
      fontSize: '11px',
      fontWeight: 800,
      marginLeft: '0px',
    },
  },
  quickAccessPromociones: {
    margin: '0px 10px',
    background: '#4AA259',
    color: 'white',
    padding: '5px',
    borderRadius: '4px',
    textDecoration: 'none',

    '&:hover': {
      background: '#3a8c49',
    },
    '& $openMenuText': {
      color: 'white',
      fontSize: '12px',
      fontWeight: 600,
      marginLeft: '0px',
    },
  },
  menuWindow: {
    marginTop: '12px',
    '& .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper': {
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.08) ',
    },
    '& ul': {
      display: 'flex',
      flexDirection: 'row',
      padding: 0,
    },
  },
  list: {
    backgroundColor: '#f9f9f9',
  },
  menuSubWindow: {
    marginTop: '12px',
    '& .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper': {
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.08) ',
    },
    '& ul': {
      display: 'flex',
      flexDirection: 'row',
      padding: 0,
    },
  },
  dropDownItem: {
    padding: '4px',
  },
  menuItemBox: {
    width: '100%',
    padding: '14px 36px',
  },
  menuSubItemBox: {
    fontSize: '13px',
    fontWeight: 600,
    padding: '3px 0px',
    '&:hover': {
      color: '#bbbbbb',
    },
  },
  dropDownItemText: {
    fontSize: '14px',
    fontWeight: 600,
    color: theme.palette.primary.almostBlack,
  },
  dropDownSubItemText: {
    fontSize: '13px',
    fontWeight: 600,
    color: theme.palette.primary.almostBlack,
    '&:hover': {
      color: '#bbbbbb',
    },
  },
  arrowIcon: {
    fontSize: '12px',
    marginLeft: '8px',
    color: theme.palette.primary.almostBlack,
  },

  arrowSubIcon: {
    fontSize: '12px',
    padding: '15px',
    color: theme.palette.primary.almostBlack,
  },

  actualChildsContainer: {
    padding: '30px 40px',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    maxHeight: '1000px',
    width: '800px',
    backgroundColor: '#F9F9F9',
  },
  actualSubChildsContainer: {
    padding: '30px 40px',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    maxHeight: '500px',
    backgroundColor: '#F9F9F9',
  },
  actualChildsItem: {
    marginBottom: '30px',
    marginRight: '50px',
    maxWidth: '400px',
  },
  actualChildsSunItem: {
    marginBottom: '30px',
    marginRight: '50px',
    maxWidth: '400px',
  },
  actualChildsItemTitle: {
    fontWeight: 600,
    fontSize: '14px',
    marginBottom: '9px',
  },
  actualChildsSubItemTitle: {
    fontWeight: 600,
    fontSize: '13px',
    marginBottom: '6px',
  },
  actualChildsItemList: {
    display: 'flex',
    flexDirection: 'column',
  },
  actualChildsChild: {
    fontWeight: 300,
    fontSize: '14px',
    color: 'black',
    marginBottom: '8px',
  },
}))
