import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useStyles } from './SucursalesPage.styles'
import {
  Box,
  Grid,
  Typography,
  Card,
  useMediaQuery,
  Modal,
} from '@mui/material'
import BottomActions from '../../components/BottomActions'
import sucursalImg from '../../assets/sucursal.png'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import EmailIcon from '@mui/icons-material/Email'
import PhoneIcon from '@mui/icons-material/Phone'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import alcanceGeo from '../../assets/alcancegeo.png'
import CloseIcon from '@mui/icons-material/Close'
import getAllSucursales from './services/getAllSucursales'
import { Helmet } from 'react-helmet'

const SucursalesPage = () => {
  const navigate = useNavigate()
  const classes = useStyles()
  const desktop = useMediaQuery((theme) => theme.breakpoints.up('sm'))
  const [alcanceModal, setAlcanceModal] = useState({
    isOpen: false,
    data: null,
  })
  const [sucursales, setSucursales] = useState([])

  useEffect(() => {
    getAllSucursales()
      .then((sucursales) => {
        setSucursales(sucursales)
      })
      .catch((e) => {
        alert('Error al cargar las sucursales')
      })
  }, [])

  const backToHome = (e) => {
    e.preventDefault()
    navigate('/')
  }

  return (
    <>
      <Helmet>
        <title>Sucursales | Villanueva</title>
        <meta name='description' content='Nuestras sucursales' />
        <meta name='keywords' content='sucursales, Villanueva' />
      </Helmet>
      <Box className={classes.container}>
        <Typography
          sx={(theme) => ({
            fontWeight: '600',
            fontSize: '18px',
            color: theme.palette.primary.almostBlack,
            margin: '20px 15px',
            [theme.breakpoints.up('sm')]: {
              margin: '46px 0px 32px',
            },
          })}
        >
          Nuestras sucursales
        </Typography>
        {!desktop && (
          <Typography className={classes.subtitle}>
            Encontrá las direcciones, horarios de atención de todas nuestras
            sucursales.
          </Typography>
        )}
        <Grid
          sx={(theme) => ({
            [theme.breakpoints.up('sm')]: {
              display: 'grid',
              gridTemplateColumns: 'repeat(3,333px)',
              gridColumnGap: '20px',
            },
          })}
        >
          {sucursales?.map((sucursal, index) => (
            <Card className={classes.card} key={index} xs={12}>
              <Grid className={classes.imgContainer}>
                <img
                  src={sucursal.photo || sucursalImg}
                  alt='Foto de la sucursal'
                  height={180}
                  width={335}
                  className={classes.sucursalImg}
                />
              </Grid>
              <Grid className={classes.sucursalInfoContainer}>
                <Typography className={classes.addressTitleActive}>
                  {sucursal?.nombre}
                </Typography>
                <Grid className={classes.sucursalInfo}>
                  <Grid container wrap='nowrap'>
                    <LocationOnIcon className={classes.sucursalIcon} />
                    <Typography className={classes.sucursalDescription}>
                      {`${sucursal?.domicilio?.calle} ${sucursal?.domicilio?.altura} - (${sucursal?.domicilio?.codigoPostal}) ${sucursal?.domicilio?.localidad} - ${sucursal?.domicilio?.provincia} - ${sucursal?.domicilio?.pais}` ??
                        ''}
                    </Typography>
                  </Grid>
                  <Grid container wrap='nowrap'>
                    <EmailIcon className={classes.sucursalIcon} />
                    <Typography className={classes.sucursalDescription}>
                      {sucursal?.contacto?.correoElectronico ?? ''}
                    </Typography>
                  </Grid>
                  <Box>
                    {sucursal?.contacto?.casaCentralPhoneNumber && (
                      <>
                        <Typography
                          className={classes.sucursalDescriptionTitle}
                        >
                          Casa central
                        </Typography>
                        <Grid container wrap='nowrap'>
                          <PhoneIcon className={classes.sucursalIcon} />
                          <Typography className={classes.sucursalDescription}>
                            {sucursal?.contacto.casaCentralPhoneNumber}
                          </Typography>
                        </Grid>
                      </>
                    )}
                    {(sucursal?.contacto?.telefonoMovil ||
                      sucursal?.contacto?.telefonoFijo ||
                      sucursal?.contacto?.fax ||
                      sucursal?.contacto?.whatsappNumber) && (
                      <Typography className={classes.sucursalDescriptionTitle}>
                        Sucursal
                      </Typography>
                    )}
                    {(sucursal?.contacto?.telefonoMovil ||
                      sucursal?.contacto?.telefonoFijo ||
                      sucursal?.contacto?.fax) && (
                      <Grid container wrap='nowrap'>
                        <PhoneIcon className={classes.sucursalIcon} />
                        <Typography className={classes.sucursalDescription}>
                          {[
                            sucursal?.contacto?.telefonoFijo || undefined,
                            sucursal?.contacto?.telefonoMovil || undefined,
                          ]
                            .filter(Boolean)
                            .join(' / ')}
                          {sucursal?.fax
                            ? ` / FAX: ${sucursal?.contacto?.fax}`
                            : ''}
                        </Typography>
                      </Grid>
                    )}
                    {sucursal?.contacto?.whatsappNumber && (
                      <Grid container wrap='nowrap' alignItems='start'>
                        <WhatsAppIcon className={classes.sucursalIcon} />
                        <Typography className={classes.sucursalDescription}>
                          {sucursal?.contacto.whatsappNumber}
                        </Typography>
                      </Grid>
                    )}
                  </Box>
                </Grid>
                <Typography
                  onClick={() =>
                    setAlcanceModal({
                      isOpen: true,
                      data: {
                        photoAlcance: sucursal.photoAlcance,
                        alcanceAddresses:
                          sucursal.alcanceAddresses || 'No disponible',
                      },
                    })
                  }
                  className={classes.viewReach}
                >
                  Ver el alcance geográfico
                </Typography>
              </Grid>
            </Card>
          ))}
        </Grid>

        {!desktop && (
          <Grid className={classes.grid}>
            <BottomActions
              firstAction={backToHome}
              firstText={'Volver al inicio'}
            />
          </Grid>
        )}
        <Modal
          open={alcanceModal.isOpen}
          onClose={() => setAlcanceModal({ isOpen: false, data: null })}
        >
          <Grid container className={classes.reachModal}>
            <Grid
              item
              sx={(theme) => ({
                width: '100%',
                [theme.breakpoints.up('sm')]: {
                  width: '50%',
                },
              })}
            >
              <Typography
                sx={{
                  marginBottom: '23px',
                  fontWeight: 700,
                  fontSize: '20px',
                  lineHeight: '24px',
                }}
              >
                Alcance geográfico
              </Typography>
              <img
                width={225}
                src={alcanceModal.data?.photoAlcance || alcanceGeo}
                className={classes.reachImage}
              />
            </Grid>
            <Grid
              sx={(theme) => ({
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                [theme.breakpoints.up('sm')]: {
                  width: '50%',
                },
              })}
            >
              <ul className={classes.modalUl}>
                {alcanceModal.data?.alcanceAddresses
                  .split('--')
                  .map((address) => (
                    <li key={address}>{address}</li>
                  ))}
              </ul>
            </Grid>
            <CloseIcon
              sx={{
                position: 'absolute',
                right: '25px',
                top: '25px',
                cursor: 'pointer',
              }}
              onClick={() => setAlcanceModal({ isOpen: false, data: null })}
            />
          </Grid>
        </Modal>
      </Box>
    </>
  )
}

export default SucursalesPage
